import { inject, observer } from 'mobx-react'

import { ApplicationStore, AccountStore, NotificationStore, UserStore } from '../../stores'
import { CustomModal, SuspensefulButton, validateLength } from '@mobilizeyourtech/vision-core-react'
import { CCol, CModalBody, CModalFooter } from '@coreui/react'
import { InviteUserForm } from '.'
import { useState } from 'react'
import { UserInviteData } from '../../stores/AccountStore'
import { validateEmail } from '../../lib/helpers/validation'

type TProps = {
  accountStore?: AccountStore
  notificationStore?: NotificationStore
  userStore?: UserStore
  isModalOpen: boolean
  closeModal: () => void
}

export const CreateModal = inject(
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
  ApplicationStore.names.userStore,
)(
  observer((props: TProps) => {
    const [formData, setFormData] = useState<Partial<UserInviteData>>({})
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let firstNameError = validateLength(formData.firstName, 2)
      let lastNameError = validateLength(formData.lastName, 2)
      let emailError = validateEmail(formData.email)

      return !firstNameError && !lastNameError && !emailError
    }

    const handleSubmit = () => {
      if (!isSubmittable()) {
        return
      } else {
        const currentAccountId = props.userStore!.currentAccountData!.id
        setHasValidated(true)
        setIsSubmitting(true)
        props
          .accountStore!.inviteUserToAccount(currentAccountId, formData)
          .then(() => {
            setTimeout(() => {
              setIsSubmitting(false)
              props.notificationStore?.setNotificationMessage(
                'User invited successfully.',
                'success',
                3000,
              )
              props.closeModal()
              setFormData({})
              setHasValidated(false)
            }, 500)
          })
          .catch(() => {
            setIsSubmitting(false)
            props.notificationStore?.setNotificationMessage(
              'Error sending invite; please try again.',
              'danger',
              3000,
            )
          })
      }
    }

    return (
      <CustomModal
        data-testid="CustomModal"
        className="CustomModal"
        isModalOpen={props.isModalOpen}
        onClose={() => props.closeModal()}
        scrollable
        headerContent={'Invite User'}
      >
        <CModalBody data-testid="modal-body-wrapper">
          <div>
            <InviteUserForm
              hasValidated={hasValidated}
              onChange={(change: Partial<UserInviteData>) => {
                setFormData(change)
              }}
              formData={formData}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CCol sm={12} className="text-end px-3 py-2 d-flex justify-content-end">
            <SuspensefulButton
              isSuspended={isSubmitting}
              onClick={handleSubmit}
              disabled={!isSubmittable() || isSubmitting}
              data-testid="submit-button"
              icon="fas fa-check"
            >
              Submit
            </SuspensefulButton>
          </CCol>
        </CModalFooter>
      </CustomModal>
    )
  }),
)
