import React, { useState } from 'react'
import { CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import {
  SignupAccountData,
  SignupAccountForm,
  ClaimRequestFormData,
  ClaimRequestForm,
} from '../components/form/fragments'
import { leftArrow } from '../components/icons/leftArrow'
import { Link } from 'react-router-dom'
import { validateEmail, validateLength } from '../lib/helpers/validation'
import ReCAPTCHA from 'react-google-recaptcha'
import { SuspensefulButton } from '@mobilizeyourtech/vision-core-react'
import { extractRestfulError } from '../lib/errors/utils'
import { Redirect } from 'react-router-dom'

type claimFormData = SignupAccountData & ClaimRequestFormData & { recaptchaToken: string | null }

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  applicationStore?: ApplicationStore
}
export const SubmitClaimRequest = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  'applicationStore',
)(
  observer((props: TProps) => {
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined)

    const [claimData, setClaimData] = useState<Partial<claimFormData>>({ recaptchaToken: null })

    const isSubmittable = (): boolean => {
      let errors = [
        validateEmail(claimData.email),
        validateLength(claimData.firstName, 2),
        validateLength(claimData.lastName, 2),
      ]

      if (errors.some((err) => !!err)) {
        return false
      }

      let presence = [claimData.recaptchaToken, claimData.uniqueEntityId].every((e) => !!e)

      return presence
    }

    const onSubmit = () => {
      if (!isSubmittable()) {
        return
      }

      setHasValidated(true)
      setIsSubmitting(true)

      props
        .userStore!.submitClaimRequest({
          email: claimData.email!,
          firstName: claimData.firstName!,
          lastName: claimData.lastName!,
          uniqueEntityId: claimData.uniqueEntityId!,
          recaptchaToken: claimData.recaptchaToken!,
        })
        .then(({ ...claimdata }) => {
          setIsSubmitting(false)
          setRedirectPath(
            `/claim_result?resultMessage=ClaimSuccess&uniqueEntityId=${claimData.uniqueEntityId}&email=${claimData.email}`,
          )
        })
        .catch((error) => {
          setIsSubmitting(false)
          const knownError = extractRestfulError(error)?.error
          if (knownError) {
            knownError === 'ClaimRequestAlreadyExists'
              ? setRedirectPath(
                  `/claim_result?resultMessage=${knownError}&uniqueEntityId=${claimData.uniqueEntityId}&email=${claimData.email}`,
                )
              : setRedirectPath(`/claim_result?resultMessage=${knownError}`)
          } else {
            props.notificationStore?.setNotificationMessage(
              'Something went wrong, please try again',
              'danger',
              3000,
            )
          }
        })
    }

    if (redirectPath) {
      return <Redirect to={redirectPath} />
    }

    return (
      <section className="Claim">
        <CForm validated={hasValidated} className="needs-validation" {...{ noValidate: true }}>
          <CContainer>
            <CCard>
              <CCardBody>
                <div className="bounded-container d-flex align-items-center">
                  <Link
                    className="nav-back clickable"
                    data-testid="nav-back"
                    aria-label="navigate back to signin"
                    tabIndex={0}
                    to="/"
                  >
                    {leftArrow()}
                  </Link>
                  <h5>Request to Claim Account</h5>
                </div>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Account</b>
                      </h6>
                      <SignupAccountForm
                        value={claimData}
                        onChange={(value) => setClaimData(value)}
                        isAccountNameHidden={true}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your Information</b>
                      </h6>
                      <ClaimRequestForm
                        value={claimData}
                        onChange={(value) => setClaimData(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CContainer className="recaptcha-container">
                  <ReCAPTCHA
                    sitekey={props.applicationStore!.siteSettings!['recaptchaSiteKey']}
                    onChange={(token) => {
                      setClaimData({ ...claimData, recaptchaToken: token })
                    }}
                    data-testid="recaptcha-checkbox"
                  />
                </CContainer>
                <CRow className="justify-content-md-center">
                  <CCol sm={2} className="d-flex justify-content-md-center">
                    <SuspensefulButton
                      isSuspended={isSubmitting}
                      onClick={onSubmit}
                      disabled={!isSubmittable() || isSubmitting}
                      data-testid="claim-request-button"
                      icon="fas fa-check"
                    >
                      Submit
                    </SuspensefulButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </CForm>
      </section>
    )
  }),
)
