import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { inject, observer } from 'mobx-react'
import { PaginatedSearchableParams } from '../../../lib/types/Params'
import { CCol, CFormLabel, CRow, CButton, CFormTextarea } from '@coreui/react'
import {
  isDirtySelectableItem,
  RichTextEditor,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
  SuspensefulButton,
} from '@mobilizeyourtech/vision-core-react'
import React from 'react'
import { GovUserExtendedDataFormData, LineOfEffortFormData } from './types'
import { GovUserClassification, TechnologyTypeResponse } from '../../../stores/ServerStore'
import { GoogleSuggestAddressInput } from 'components/custom'
import classNames from 'classnames'
import DatePicker from 'react-date-picker'

export interface GovUserExtendedDataProfileFormProps {
  value: Partial<GovUserExtendedDataFormData>
  onChange: (value: Partial<GovUserExtendedDataFormData>) => void
  applicationStore?: ApplicationStore
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}

export const GovUserExtendedDataProfileForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: GovUserExtendedDataProfileFormProps) => {
    // TECH TYPES DROPDOWN START //////

    const getTechnologyTypes = (params: PaginatedSearchableParams) => {
      return props.serverStore!.getTechnologyTypes(params).then((response) => ({
        pagination: response.pagination,
        data: response.data.map((r) => {
          return { ...r, label: r.name }
        }),
      }))
    }
    const getSelectedTechnologyTypes = () => {
      if (props.value.technologyTypes === undefined) {
        return []
      }

      return props.value.technologyTypes?.map((t) => {
        return { ...t, label: t.name }
      })
    }

    const renderCityStateInput = () => {
      let locationValues: Array<String | undefined> = []
      if (props.value.location) {
        const { city, stateOrProvince, country } = props.value.location
        locationValues = [city, stateOrProvince, country].filter(Boolean)
      }

      let placeholder = locationValues.length
        ? `${locationValues.join(', ')}`
        : 'Search City and State/Province...'

      return (
        <CRow className="mb-2">
          <CFormLabel
            {...{ htmlFor: 'govUserExtendedDataCityAndState' }}
            className="col-sm-2 col-form-label"
          >
            City/State
          </CFormLabel>
          <CCol
            className={classNames('location-input', locationValues.length > 0 && 'prepopulated')}
            data-testid="location-input"
          >
            <GoogleSuggestAddressInput
              onChange={(value) => {
                props.onChange({ ...props.value, location: value })
              }}
              value={props.value.location || {}}
              hideManualEntry
              googlePlacesAutoCompleteProps={{
                autocompletionRequest: {
                  types: ['(regions)'],
                },
              }}
              placeholder={placeholder}
            />
          </CCol>
        </CRow>
      )
    }

    const renderGovEmployeeSinceYearInput = () => {
      return (
        <CRow className="mb-2 align-items-center">
          <CFormLabel
            {...{ htmlFor: 'govUserExtendedDataGovEmployeeSinceYear' }}
            className="col-8 col-form-label"
          >
            What year did you begin your career in government/Department of Defense service?
          </CFormLabel>
          <DatePicker
            className={classNames('col-4 gov-employee-since-year-input')}
            value={
              props.value.govEmployeeSinceYear
                ? new Date(props.value.govEmployeeSinceYear, 0, 1)
                : undefined
            }
            onChange={(year: Date) =>
              props.onChange({
                ...props.value,
                govEmployeeSinceYear: year ? year.getFullYear() : undefined,
              })
            }
            format="yyyy"
            maxDetail="decade"
            maxDate={new Date()}
            data-testid="gov-employee-since-year-input"
          />
        </CRow>
      )
    }

    const renderLineOfEffortInputs = () => {
      const lineOfEffortArray = (props.value.linesOfEffort as LineOfEffortFormData[]) || []

      return lineOfEffortArray.map((loe, idx) => {
        return !loe.destroy ? (
          <div key={idx} className="d-flex flex-column w-100">
            {idx !== 0 && (
              <div className="d-flex justify-content-end mb-1">
                <CButton
                  onClick={() => removeLineOfEffort(idx)}
                  className="delete-line-of-effort-btn d-flex justify-content-center align-items-center"
                  color="dark"
                  data-testid="remove-loe-button"
                >
                  <i className="fas fa-times" />
                </CButton>
              </div>
            )}
            <CFormTextarea
              maxLength={300}
              value={loe.details}
              onChange={(e) => updateLineOfEffort(e.target.value, idx)}
              data-testid="loe-input"
            />
          </div>
        ) : null
      })
    }

    const addLineOfEffort = () => {
      const newLineOfEffort: LineOfEffortFormData = { details: '' }
      props.onChange({
        linesOfEffort: [...(props.value.linesOfEffort || []), newLineOfEffort],
      })
    }

    const removeLineOfEffort = (idx: number) => {
      // TODO - this is temporary. To be refactored when implementing EDIT
      const updatedLineOfEffort = [...props.value.linesOfEffort!]

      const item = updatedLineOfEffort[idx]
      if (item?.id) {
        updatedLineOfEffort[idx] = { ...item, destroy: true }
      } else {
        updatedLineOfEffort.splice(idx, 1)
      }

      props.onChange({ linesOfEffort: updatedLineOfEffort })
    }

    const updateLineOfEffort = (value: string, idx: number) => {
      const updatedLineOfEffort = props.value.linesOfEffort!.map((loe, i) =>
        i === idx ? { ...loe, details: value } : loe,
      )
      props.onChange({
        linesOfEffort: updatedLineOfEffort,
      })
    }

    // TECH TYPES DROPDOWN END ///////

    const technologyTypesRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel className="w-100 col-xs-12 col-form-label">
            Technology Types <i className="fas fa-asterisk gov-required-icon" />
            (required)
          </CFormLabel>
          <CCol lg={9} xs={12}>
            <ServerBackedMultiSelectAutoComplete
              required
              loadMore={getTechnologyTypes}
              onChange={(selected) => {
                props.onChange({
                  technologyTypes: selected.filter((e) => !isDirtySelectableItem(e)) as Array<
                    TechnologyTypeResponse & SelectableItem
                  >,
                })
              }}
              selections={getSelectedTechnologyTypes()}
              placeholder="Select Technology Types..."
              className="technology-type-search-dropdown ValidatedInput"
              data-testid="technology-type-search-dropdown"
            />
          </CCol>
        </CRow>
      )
    }

    const linesOfEffortRow = () => {
      return (
        <CRow className="mb-3">
          <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
            <CFormLabel className="w-100 col-sm-2 col-form-label">
              Lines of Effort <i className="fas fa-asterisk gov-required-icon" />
              (required)
            </CFormLabel>
            <span className={'subtle'}>
              What area or problem are you looking for new emerging technologies to solve for your
              mission? (up to 6)
              <br />
              The more detail, the better. LOEs with more than 20 words (about 3 sentences) tend to
              generate more specific matches in IGNITE. Don’t hesitate to elaborate on what you’re
              looking for.
            </span>
            <CCol className="mt-2">
              {renderLineOfEffortInputs()}
              <div className="d-flex justify-content-end align-items-end py-2">
                {props.value.linesOfEffort &&
                  props.value.linesOfEffort.filter((loe) => !loe.destroy).length < 6 && (
                    <SuspensefulButton
                      icon="fas fa-plus"
                      className="d-inline justify-self-end align-self-end"
                      isSuspended={false}
                      onClick={addLineOfEffort}
                      data-testid="add-loe-button"
                    >
                      Add new line of effort
                    </SuspensefulButton>
                  )}
              </div>
            </CCol>
          </CCol>
          <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-5 exampleDiv'}>
            <div>Example:</div>
            <p>
              Air Force Special Warfare unit is looking for portable electronic warfare systems with
              excellent SWaP characteristics for field use. They must be able to cover 2-10GHz.
            </p>
          </CCol>
        </CRow>
      )
    }

    const profileDetailsRow = () => {
      return (
        <CRow className="mb-3">
          <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
            <CFormLabel className="w-100 col-sm-2 col-form-label">
              About you <i className="fas fa-asterisk gov-required-icon" />
              (required)
            </CFormLabel>
            <span className={'subtle'}>
              Role description and anything else you want people to know.
            </span>
            <CCol>
              <RichTextEditor
                className="profile-details-editor"
                data-testid="profile-details-editor"
                value={props.value.profileDetail}
                onChange={(value) => {
                  props.onChange({ profileDetail: value })
                }}
              />
            </CCol>
          </CCol>
          <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-5 exampleDiv'}>
            <div>Example:</div>
            <p>
              Hi! I am a program manager working specifically in advanced manufacturing. I’m
              especially interested in innovative 3D printing opportunities.
            </p>
          </CCol>
        </CRow>
      )
    }

    const getGovUserClassifications = async (p: PaginatedSearchableParams) => {
      return props
        .serverStore!.getGovUserClassifications({
          ...p,
          eager: 'govUserClassificationCategory',
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.map((classification) => {
              return {
                ...classification,
                subLabel: classification.description,
                categoryLabel: classification.govUserClassificationCategory
                  ? classification.govUserClassificationCategory.name
                  : 'Other',
              }
            }),
          }
        })
    }

    const govUserClassificationsRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel className="w-100 col-xs-12 col-form-label">
            How do you want to interact with small businesses in IGNITE?{' '}
            <i className="fas fa-asterisk gov-required-icon" />
            (required)
          </CFormLabel>
          <span className="subtle col-xs-12 mb-2">
            If selecting Technical Point of Contact, please reference{' '}
            <a href="https://afwerx.com/wp-content/uploads/TPOC-FAQs-Open-Topic-SBIR-CLEARED_AFRL-2024-2219.pdf">
              these listed duties.
            </a>
            <br />
            If you are unsure if you can sign a customer memo, please reference{' '}
            <a href="https://afwerx.com/divisions/ventures/open-topic/">
              open topic resources.
            </a>{' '}
            More often than not, you are able to sign a customer memo.
          </span>
          <CCol lg={9} xs={12}>
            <ServerBackedMultiSelectAutoComplete
              loadMore={getGovUserClassifications}
              onChange={(selected) => {
                props.onChange({
                  govUserClassifications: selected.filter(
                    (e) => !isDirtySelectableItem(e),
                  ) as Array<GovUserClassification & SelectableItem>,
                })
              }}
              selections={props.value.govUserClassifications || []}
              placeholder="Select desired interactions"
              data-testid="user-classification-multiselect"
              className="gov-user-classifications-multiselect ValidatedInput"
            />
          </CCol>
        </CRow>
      )
    }

    return (
      <div className={'GovUserExtendedDataProfileForm'}>
        {renderCityStateInput()}
        {renderGovEmployeeSinceYearInput()}
        {linesOfEffortRow()}
        {technologyTypesRow()}
        {govUserClassificationsRow()}
        {profileDetailsRow()}
      </div>
    )
  }),
)
