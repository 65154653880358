import { useState, useEffect } from 'react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { PropsWithChildren } from 'react'
import { CModalBody, CModalFooter } from '@coreui/react'
import {
  CustomModal,
  SuspensefulButton,
  SuspensionBoundary,
} from '@mobilizeyourtech/vision-core-react'
import {
  isListingArtifactSubmittable,
  ListingArtifactFormWizard,
  ListingArtifactFormData,
} from './ListingArtifactFormWizard'
import { isUseCase, ListingServiceProductData, ListingUseCaseData } from '../../stores/ListingStore'
import { capitalizeFirstLetter, titleCase } from '../../lib/helpers/utils'

export type ListingArtifactFormModalProps = {
  listingId: string
  artifact: 'product' | 'service' | 'use case'
  method: 'get' | 'create' | 'update'
  onSubmit: (artifact: string, responseId: string | number) => void
  visible: boolean
  setVisibility: (bool: boolean) => void
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  artifactId?: string | number
  /** Optional ID - will preload data for id if present */
  timeout?: number
  /** Optional timeout interval - mostly for testing, will default to 300 if absent */
}
interface ListingArtifactDirectory {
  [key: string]: {
    [key: string]: () => Promise<ListingServiceProductData>
  }
}

export const ListingArtifactFormModal = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer(
    ({
      artifact,
      listingId,
      method,
      ...props
    }: PropsWithChildren<ListingArtifactFormModalProps>) => {
      const [formData, setFormData] = useState<Partial<ListingArtifactFormData>>({})
      const [suspending, setSuspense] = useState<boolean>(false)

      const directory: ListingArtifactDirectory = {
        service: {
          create: () =>
            props.listingStore!.createListingService({
              listingId,
              payload: formData as ListingArtifactFormData,
            }),
          update: () =>
            props.listingStore!.updateListingService({
              listingId,
              serviceId: props.artifactId as string,
              payload: formData as ListingArtifactFormData,
            }),
          get: () =>
            props.listingStore!.getSingleListingService({
              listingId,
              serviceId: props.artifactId as string,
              eager: 'links,attachments,technologyTypes',
            }),
        },
        product: {
          create: () =>
            props.listingStore!.createListingProduct({
              listingId,
              payload: formData as ListingArtifactFormData,
            }),
          update: () =>
            props.listingStore!.updateListingProduct({
              listingId,
              productId: props.artifactId as string,
              payload: formData as ListingArtifactFormData,
            }),
          get: () =>
            props.listingStore!.getSingleListingProduct({
              listingId,
              productId: props.artifactId as string,
              eager: 'links,attachments,technologyTypes',
            }),
        },
        'use case': {
          create: () =>
            props.listingStore!.createListingUseCase({
              listingId,
              payload: formData as ListingArtifactFormData,
            }),
          update: () =>
            props.listingStore!.updateListingUseCase({
              listingId,
              useCaseId: props.artifactId as string,
              payload: formData as ListingArtifactFormData,
            }),
          get: () =>
            props.listingStore!.getSingleListingUseCase({
              listingId,
              useCaseId: props.artifactId as string,
              eager: 'links,attachments',
            }),
        },
      }

      useEffect(() => {
        if (props.artifactId) {
          getData()
        }
      }, [props.artifactId])

      const composePreloadedData = (response: ListingServiceProductData | ListingUseCaseData) => {
        let dataWithAttributes = {
          listingArtifactAttachmentsAttributes: response.listingArtifactAttachments!.map(
            (attachment) => {
              return { destroy: false, destroyable: true, ...attachment }
            },
          ),
          listingArtifactLinksAttributes: response.listingArtifactLinks!.map((link) => {
            return { destroy: false, destroyable: true, ...link }
          }),
          listingArtifactTechnologyTypesAttributes: !isUseCase(response)
            ? response.listingArtifactTechnologyTypes!.map((techType) => {
                // Formulate this for presence in a dropdown of Technology Type selectables
                return {
                  id: techType.technologyTypeId,
                  ...techType,
                  destroy: false,
                  destroyable: true,
                }
              })
            : undefined,

          ...response,
        }

        let {
          listingArtifactLinks,
          listingArtifactAttachments,
          listingArtifactTechnologyTypes,
          ...composedFormData
        } = dataWithAttributes

        return composedFormData
      }

      const getData = async () => {
        try {
          let response = await directory[artifact]['get']()
          setFormData(composePreloadedData(response))
        } catch {
          props.notificationStore?.setNotificationMessage(
            `Unable to load data for listing ${artifact} - please reload and try again.`,
            'danger',
            3000,
          )
        }
      }

      const canSubmit = () => isListingArtifactSubmittable(formData)

      const switchOff = () => {
        props.setVisibility(false)
        setTimeout(() => {
          setFormData({})
        }, props.timeout || 300)
      }

      const onSubmit = async () => {
        setSuspense(true)

        try {
          let response = await directory[artifact][method]()
          switchOff()
          props.onSubmit(artifact, response.id)
          props.notificationStore?.setNotificationMessage(
            `Successfully submitted listing ${artifact}`,
            'success',
            3000,
          )
        } catch (err) {
          props.notificationStore?.setNotificationMessage(
            `Unable to submit ${method} listing ${artifact} form at the moment, please try again later.`,
            'danger',
            3000,
          )
        } finally {
          setSuspense(false)
        }
      }

      const composeHeaderText = () => {
        return capitalizeFirstLetter(method) + ' Listing ' + titleCase(artifact)
      }

      return (
        <section
          className="ListingArtifactFormModal"
          data-testid={`ListingArtifactFormModal-${method}`}
        >
          <CustomModal
            isModalOpen={props.visible}
            onClose={() => switchOff()}
            size="lg"
            headerContent={composeHeaderText()}
            scrollable
          >
            <CModalBody>
              <SuspensionBoundary
                isLoading={!!props.artifactId && Object.keys(formData).length === 0}
              >
                <ListingArtifactFormWizard
                  formData={formData}
                  onChange={(change: Partial<ListingArtifactFormData>) => {
                    setFormData({ ...formData, ...change })
                  }}
                  submittable={canSubmit()}
                  artifact={artifact}
                />
              </SuspensionBoundary>
            </CModalBody>
            <CModalFooter>
              <SuspensefulButton
                data-testid={'submit-button'}
                isSuspended={suspending}
                onClick={() => onSubmit()}
                disabled={suspending || !canSubmit()}
                icon="fas fa-check"
              >
                Submit
              </SuspensefulButton>
            </CModalFooter>
          </CustomModal>
        </section>
      )
    },
  ),
)
