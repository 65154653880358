import {
  Avatar,
  CustomModal,
  CustomModalProps,
  SuspensefulButton,
} from '@mobilizeyourtech/vision-core-react'
import { ApplicationStore, ServerStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { ConnectListingResponse, isConnectListingResponse } from '../../stores/ConnectListingStore'
import { GovUserData } from '../../stores/UserStore'
import { ChatParticipantIdentifiers } from '../../stores/ServerStore'
import { CFormTextarea } from '@coreui/react'
import { useState } from 'react'
import { validateLength } from '../../lib/helpers/validation'
import { useHistory } from 'react-router-dom'
import { Alert } from './Alert'
import { UNCLASSIFIED_WARNING_PROMPT } from '../../lib/helpers/utils'

export interface InitiateChatModalProps extends CustomModalProps {
  serverStore?: ServerStore
  target: ConnectListingResponse | GovUserData
  participants: Array<ChatParticipantIdentifiers>
}

export const InitiateChatModal = inject(ApplicationStore.names.serverStore)(
  observer(({ serverStore, target, participants, ...props }: InitiateChatModalProps) => {
    const history = useHistory()
    const [message, setMessage] = useState<string>('')
    const [isSubmitSuspended, setIsSubmitSuspended] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const renderAvatar = () => {
      const first = isConnectListingResponse(target) ? target.name : target.firstName
      const last = isConnectListingResponse(target) ? '' : target.lastName
      const src = isConnectListingResponse(target) ? target.logo?.path : target.headshot?.path
      return <Avatar prefix={first} suffix={last} src={src || ''} />
    }

    const renderName = () => {
      return isConnectListingResponse(target)
        ? target.name
        : `${target.firstName} ${target.lastName}`
    }

    const onSubmit = () => {
      setIsSubmitSuspended(true)
      setError(false)
      serverStore!
        .createChatThread(participants, message)
        .then((chatThread) => {
          history.push(`/chat?threadId=${chatThread.id}`)
        })
        .catch(() => {
          setError(true)
          setIsSubmitSuspended(false)
        })
    }

    return (
      <CustomModal
        {...props}
        onClose={() => {
          props.onClose()
          setError(false)
          setMessage('')
          setIsSubmitSuspended(false)
        }}
      >
        <section
          className="InitiateChatForm d-flex flex-column gap-2 px-3 mb-3"
          data-testid={'InitiateChatModal'}
        >
          <div className="form-header d-flex flex-row gap-2 align-items-center">
            <div className="pe-2">{renderAvatar()}</div>
            <div>{renderName()}</div>
          </div>
          {error && (
            <Alert
              color={'danger'}
              onToggleAlert={() => setError(false)}
              message={'Error sending message. Please try again.'}
            />
          )}
          <CFormTextarea
            data-testid="initiate-chat-textarea-input"
            className="mt-3"
            placeholder={'Send a message...'}
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />
          <span className={'subtle'}>{UNCLASSIFIED_WARNING_PROMPT}</span>
          <div className="d-flex w-100 justify-content-end mt-1">
            <SuspensefulButton
              data-testid="initiate-chat-send-button"
              isSuspended={isSubmitSuspended}
              disabled={!!validateLength(message, 2)}
              onClick={onSubmit}
              icon="fas fa-check"
            >
              Send
            </SuspensefulButton>
          </div>
        </section>
      </CustomModal>
    )
  }),
)
