import React, { useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import { SuspensefulButton } from '@mobilizeyourtech/vision-core-react'
import {
  isResendClaimFormDataAlternative,
  ResendClaimFormData,
  ResendClaimFormDataAlternative,
} from '../../stores/UserStore'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  formData: ResendClaimFormData | ResendClaimFormDataAlternative
}
export const ResendClaimForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const onSubmit = () => {
      setIsSubmitting(true)

      props
        .userStore!.resendClaim(props.formData)
        .then(({ ...claimdata }) => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Claim email has been resent.',
            'success',
            3000,
          )
        })
        .catch((error) => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to resend claim email, please try again.',
            'danger',
            3000,
          )
        })
    }

    return (
      <section className="EditProfileForm" data-testid="change-password-form">
        <CForm data-testid="resend-form">
          {isResendClaimFormDataAlternative(props.formData) && (
            <>
              <CRow className="mb-2 justify-content-md-center">
                <CCol>
                  <CFormLabel {...{ htmlFor: 'resend-claim-uei-input' }} className="col-form-label">
                    Unique Entity Id
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="resend-claim-uei-input"
                    data-testid="uei-input"
                    value={props.formData.uniqueEntityId}
                    readOnly={true}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-2 justify-content-md-center">
                <CCol>
                  <CFormLabel
                    {...{ htmlFor: 'resend-claim-email-input' }}
                    className="col-form-label"
                  >
                    Email
                  </CFormLabel>
                  <CFormInput
                    type="email"
                    id="resend-claim-email-input"
                    data-testid="email-input"
                    value={props.formData.email}
                    readOnly={true}
                  />
                </CCol>
              </CRow>
            </>
          )}

          <CRow className="mb-2 justify-content-md-center">
            <CCol className="d-flex justify-content-md-center">
              <SuspensefulButton
                isSuspended={isSubmitting}
                onClick={onSubmit}
                disabled={isSubmitting}
                data-testid="claim-resend-button"
                icon="fas fa-check"
              >
                Resend
              </SuspensefulButton>
            </CCol>
          </CRow>
        </CForm>
      </section>
    )
  }),
)
